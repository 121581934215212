<template>
  <editable-list
    ref="list"
    class="app-adb-list"
    :data="records"
    :data-manager="dataManager"
    :fields="fields"
    :sortOrder="sortOrder"
    trackBy="version"
    @remove-row="remove"
  >
    <template v-slot:title="">
      <div class="form-col w-1/2 py-2">
        <label>relationship type</label>
        <Dropdown
          :options="relationshipTypes"
          :value="currentRelationshipType"
          @input="setRelationshipType"
          show-all
          no-clear
        />
      </div>
    </template>
    <template v-slot:row-template="">
      <Form :submit="add">
        <template v-slot="props">
          <div class="row-template">
            <div class="form-row items-center">
              <div class="field-version text-sm">
                next <Tooltip icon="info" text="A new version is generated automatically"/>
              </div>
              <div class="field-value">
                <TextField name="value" placeholder="value" clearable />
              </div>
              <row-template-actions class="form-row justify-end" @cancel="cancel" :disabled="props.invalid"></row-template-actions>
            </div>
          </div>
        </template>
      </Form>
    </template>
  </editable-list>
</template>

<script>
  import ModalNavigation from "@/mixins/ModalNavigation";
  import ValidatorMixin from '@/components/form/ValidatorMixin';
  import ConfirmationMixin from "@/mixins/ConfirmationMixin";
  import NotifyMixin from "@/mixins/NotifyMixin";
  import Dropdown from "@/components/ui/Dropdown";
  import Tooltip from "@/components/ui/Tooltip";
  import Form from "@/components/form/Form";
  import TextField from "@/components/form/TextField";
  import EditableList from "@/components/ui/inlineediting/EditableList";
  import RowTemplateActions from "@/components/ui/inlineediting/RowTemplateActions";
  import TextEditableField from "@/components/auth/list/fields/TextEditableField";

  export default {
    name: "AppDataBlockList",
    components: {
      Dropdown,
      Form,
      TextField,
      EditableList,
      RowTemplateActions,
      Tooltip,
    },
    mixins: [
      ModalNavigation,
      ValidatorMixin,
      ConfirmationMixin,
      NotifyMixin,
    ],
    props: {
      readonly: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        loading: false,
        currentRelationshipType: null,
        relationshipTypes: [],
        records: [],
        fields: [
          {
            name: 'version',
            title: 'version',
            titleClass: 'relative pr-2',
            dataClass: 'pr-2 text-sm',
            width: '10%',
            sortField: 'version',
          },
          {
            name: TextEditableField,
            title: 'value',
            titleClass: 'relative px-2',
            dataClass: 'px-2',
            width: '80%',
            switch: {
              clipboard: true,
              fieldName: 'value',
              onUpdate: this.updateValue,
            }
          },
          {
            name: 'inline-actions',
            title: '',
            titleClass: 'relative px-2',
            dataClass: 'px-2',
            width: '10%',
          }
        ],
        sortOrder: [
          {
            field: 'version',
            direction: 'desc'
          }
        ],
      }
    },
    computed: {
      requestParams: function () {
        return {
          appCode: this.$route.params.code,
          type: this.currentRelationshipType,
        };
      },
    },
    watch: {
      currentRelationshipType: {
        handler: function (value) {
          if (value) {
            this.fetchDataBlocks();
          }
        },
        immediate: true
      },
      sortOrder: {
        handler: function (sort) {
          if (sort[0] && this.currentRelationshipType) {
            this.fetchDataBlocks();
          }
        },
        deep: true
      },
    },
    methods: {
      add(values) {
        this.$raaDataProvider.create('appDataBlocks', { ...this.requestParams, data: values })
          .then((response) => {
            this.sortOrder[0]?.direction === 'asc' ? this.records.push(response) : this.records.unshift(response);
            this.$refs.list.hideNewRow();
            this.notifySuccess('data block is created');
          })
          .catch(error => this.notifyError(error.message));
      },
      cancel() {
        this.$refs.list.hideNewRow();
      },
      updateValue(value, { data, ref }) {
        this.loading = true;
        this.$raaDataProvider.update('appDataBlockVersioned', {
          ...this.requestParams,
          version: data.version,
          data: { value }
        })
          .then(() => {
            ref.editMode = false;
            this.notifySuccess('The value is updated successfully');
          })
          .catch((error) => {
            this.notifyError(error.message);
          })
          .finally(() => this.loading = false);
      },
      remove(index, { version }) {
        this.requestConfirmation({
          confirmationMessage: 'Are you sure you want to delete this data block?',
          confirmBtnText: 'yes',
          cancelBtnText: 'no',
          confirmationType: 'warning'
        })
          .then(confirmed => confirmed
            ? this.$raaDataProvider.delete('appDataBlockVersioned', { ...this.requestParams, version })
              .then(() => {
                this.records.splice(index,1);
                this.notifySuccess('data block is deleted');
              })
              .catch(error => this.notifyError(error.message))
            : null
          );
      },
      setRelationshipType(value) {
        if (value) {
          this.currentRelationshipType = value;
        }
      },
      fetchRelationshipTypes() {
        this.$raaDataProvider.getList('relationshipTypes', {size: 999})
          .then((result) => {
            this.relationshipTypes = result.map(({type, name}) => ({
              key: type,
              value: name.toLocaleLowerCase(),
            }));

            this.setRelationshipType(this.relationshipTypes[0]?.key);
          })
          .catch((err) => this.notifyError(err.message));
      },
      fetchDataBlocks() {
        const sort = this.sortOrder[0] ? `${this.sortOrder[0].sortField},${this.sortOrder[0].direction}` : undefined;

        this.$raaDataProvider.getList('appDataBlocks', {
          ...this.requestParams,
          size: 999,
          sort,
        })
          .then(({content}) => {
            this.records =  content;
          })
          .catch((error) => this.notifyError(error.message))
          .finally(() => this.loading = false);
      },
      dataManager(sortOrder = []) {
        this.sortOrder = sortOrder;
      },
    },
    mounted() {
      this.fetchRelationshipTypes();
    }
  }
</script>

<style scoped>
  .row-template :deep(.field-version) {
    @apply pl-4 pr-2;
    width: 10%;
  }
  .row-template :deep(.field-value) {
    @apply px-2 flex-1;
  }

  .app-adb-list :deep(.editable-list-title) {
    @apply items-end;
  }
</style>

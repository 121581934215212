<template>
  <Details
    title="application"
    resource="applications"
    :base-path="basePath"
    data-provider="$raaDataProvider"
    route-id-param="code"
    title-template="{name} application"
    breadcrumb-template="{name} application details"
  >
    <template v-slot:default="detailsProps">
      <div>
        <div v-if="detailsProps.record.deletedAt" class="flex justify-end text-sm mb-4">
          {{detailsProps.getDeletedLabel(detailsProps.record.deletedAt)}}
        </div>
      </div>
      <DetailsBar class="mb-10 flex-col relative">
        <div class="form-row form-narrow w-full">
          <div class="form-col flex">
            <div class="form-col">
              <label class="label">name</label>
              <div>{{detailsProps.record.name}}</div>
            </div>
            <div class="form-col">
              <label class="label">code</label>
              <div>{{detailsProps.record.code}}</div>
            </div>
          </div>
          <div class="form-col">
            <label class="label">api endpoint</label>
            <div>{{detailsProps.record.apiEndpoint}}</div>
          </div>
        </div>
        <div v-if="!detailsProps.record.deletedAt" class="absolute top-3 right-3">
          <button @click="editMainInformation">
            <icon name="pencil" class="edit-icon"/>
          </button>
        </div>
      </DetailsBar>

      <div class="font-semibold py-4 flex items-center">
        <span class="font-semibold text-sm text-black mr-2">
          management of relationship types specific authorized data in application area
        </span>
        <Tooltip icon="info" text="relationship type specific authorized data can be set for the application in a free form. it will be available for profiles of the specified relationship type of a certain version"/>
      </div>
      <app-data-block-list :readonly="Boolean(detailsProps.record.deletedAt)"></app-data-block-list>
    </template>
  </Details>
</template>

<script>
  import ModalNavigation from "@/mixins/ModalNavigation";
  import ValidatorMixin from '@/components/form/ValidatorMixin';
  import Details from "@/components/auth/details/Details";
  import DetailsBar from '@/components/auth/details/DetailsBar';
  import Tooltip from "@/components/ui/Tooltip";
  import Icon from "@/components/ui/Icon";
  import AppDataBlockList from "./data-block/AppDataBlockList";

  export default {
    name: "ApplicationDetails",
    components: {
      AppDataBlockList,
      Details,
      DetailsBar,
      Icon,
      Tooltip,
    },
    mixins: [ModalNavigation, ValidatorMixin],
    computed: {
      basePath: function () {
        return this.$route.name.replace('details', 'index');
      },
      appCode: function () {
        return this.$route.params.code;
      },
    },
    methods: {
      editMainInformation() {
        this.$router.push({
          name: this.$route.name.replace('details', 'edit'),
          params: this.$route.params,
        });
      },
    },
  }
</script>
